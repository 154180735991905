/* eslint-disable max-len */
import { LINKS, PAGES } from 'src/constants';
import { toAddBillingAddressRequest, toAddServiceAddressRequest, toMaintenanceRequest, toPurchaseRequest, toRetryAddressRequest } from 'src/helpers/transformers';
import { useApiState, useCart, useModal, useSiteMetadata, useSpinner, useUrl } from 'src/hooks';
import ApiError from 'src/components/molecules/api-error';
import React from 'react';
import { navigate } from 'gatsby';

import type { GetLinkConfig, Request } from 'src/hooks/useApi/_types';
import type { EmptyFunction } from 'src/_types';

import { PageViewData } from 'plugins/custom-plugin-adobe-launch/_types';
import { pushMessageViewEvent } from 'plugins/custom-plugin-adobe-launch';

export const DATA_LAYER_NAME = 'adobeDataLayer';

const {
  ADD_BILLING_ADDRESS,
  ADD_SERVICE_ADDRESS,
  APPLY_DISCOUNT,
  GET_PLANS,
  PURCHASE,
  RETRY_CURRENT_ADDRESS,
  RETRY_NEW_ADDRESS,
  RETRY_SUGGESTED_ADDRESS,
  MAINTENANCE_PAGE
} = LINKS;

const { MAINTENANCE } = PAGES;

const push = (event: any) => (window as any)?.[DATA_LAYER_NAME]?.push?.(event);
const getBrand = () => location.hostname?.split('.')?.reverse()?.[1] ?? '';

const handleError = ({ error, onComplete, onFailure, onRetry, saveApiState, showModal }: any) => Promise.resolve(error)
  .then((resolvedError) => {
    const { _links = {}, _meta = {} } = resolvedError;

    saveApiState({ _links, _meta });
    if (resolvedError.code === '500100') {
      pushMaintenancePageView();
      navigate(MAINTENANCE);
    } else if (resolvedError.code === '400204' || resolvedError.code === '400205') {
      const serviceInfo = sessionStorage.getItem('serviceInfo');
      let serviceInformation = null;
      if (serviceInfo) {
        serviceInformation = JSON.parse(serviceInfo);
      }
      const billingInfo = sessionStorage.getItem('billingInfo');
      let billingInformation = null;
      if (billingInfo) {
        billingInformation = JSON.parse(billingInfo);
      }
      const checkout = {
        stepDescription: 'Service Details',
        stepName: 'Step 1',
        stepStatus: 'Inprogress'
      };
      const modal = {
        dialogAction: 'Inprogress',
        dialogDescription: 'Unmatched Address',
        dialogName: 'Confirm USPS Address'
      };

      const messages = {
        error: resolvedError.message,
        errorDetail: serviceInformation?.city + '|' + serviceInformation?.state + '|' + serviceInformation?.zipCode
      };
      pushCheckOutSteps(billingInformation, checkout, messages, modal, serviceInformation);
      showModal({ innerContent: <ApiError error={resolvedError} onComplete={onComplete} onRetry={onRetry} /> });
    } else {
      pushMessageViewEvent({
        event: 'messageView',
        messages: {
          error: resolvedError.message
        }
      });
      showModal({ innerContent: <ApiError error={resolvedError} onComplete={onComplete} onRetry={onRetry} /> });
    }

    onFailure?.();
  });

const pushMaintenancePageView = (pathname = '', pageViewData: PageViewData = {}) => push({
  event: 'pageView',
  messages: { siteWide: 'Maintenance in Progress' },
  page: {
    brand: getBrand(),
    language: 'en',
    pageDetail: '',
    pageExtraDetail: '',
    salesAgentPartnerId: '',
    salesAgentVendor: '',
    siteSection: 'nrgprotects',
    subSection1: 'home',
    subSection2: 'outage',
    ...pageViewData[pathname]
  }
});

const pushCheckOutSteps = (billingInfo?: any, checkout?: any, messages?: any, modal?: any, serviceInfo?: any) => push({

  billingInfo: billingInfo,
  checkout: checkout,
  event: 'checkoutSteps',
  global: {
    brand: getBrand(),
    eventAction: 'checkoutSteps'
  },
  messages: messages,
  modal: modal,
  serviceInfo: serviceInfo
});

const transformRequestData = ({ cart, data, id, linkBody }: any) => {
  const transformers: any = {
    [ADD_BILLING_ADDRESS]: () => toAddBillingAddressRequest(data, linkBody),
    [ADD_SERVICE_ADDRESS]: () => toAddServiceAddressRequest(data, cart),
    [MAINTENANCE_PAGE]: () => toMaintenanceRequest(data, linkBody),
    [PURCHASE]: () => toPurchaseRequest(data, linkBody),
    [RETRY_CURRENT_ADDRESS]: () => toRetryAddressRequest(data, linkBody),
    [RETRY_NEW_ADDRESS]: () => toRetryAddressRequest(data, linkBody),
    [RETRY_SUGGESTED_ADDRESS]: () => toRetryAddressRequest(data, linkBody)
  };

  return transformers[id]?.(data) ?? data;
};

const request: Request =
  ({ body, hideSpinner, method, onComplete, onFailure, onRetry, saveApiState, showModal, showSpinner, url }) => {
    const headers = { 'Content-Type': 'application/json' };
    showSpinner();

    return fetch(url, { body: body && JSON.stringify(body), headers, method })
      .then(response => response.ok ? response.json() : Promise.reject(response.json()))
      .then(response => saveApiState?.(response))
      .then(() => { onComplete?.(), sessionStorage.getItem('addServiceInfo') || sessionStorage.getItem('addNewServiceInfo') ? serviceInfoComplete() : '', sessionStorage.getItem('purchase') ? purchaseComplete() : ''; })
      .catch((error) => handleError({ error, onComplete, onFailure, onRetry, saveApiState, showModal }))
      .finally(() => hideSpinner());
  };

const serviceInfoComplete = () => {
  const checkout = {
    stepDescription: 'Service Details',
    stepName: 'Step 1',
    stepStatus: 'Complete'
  };
  const serviceInfo = sessionStorage.getItem('serviceInfo');
  let serviceInformation = null;
  if (serviceInfo) {
    serviceInformation = JSON.parse(serviceInfo);
  }
  const billingInfo = sessionStorage.getItem('billingInfo');
  let billingInformation = null;
  if (billingInfo) {
    billingInformation = JSON.parse(billingInfo);
  }
  let modal = null;

  if (sessionStorage.getItem('addNewServiceInfo')) {
    modal = {
      dialogAction: 'Complete',
      dialogDescription: 'Enter New Address',
      dialogName: 'Confirm USPS Address'
    };
  }

  pushCheckOutSteps(billingInformation, checkout, null, modal, serviceInformation);
  sessionStorage.removeItem('addServiceInfo');
  sessionStorage.removeItem('addNewServiceInfo');
};

const purchaseComplete = () => {
  const serviceInfo = sessionStorage.getItem('serviceInfo');
  let serviceInformation = null;
  if (serviceInfo) {
    serviceInformation = JSON.parse(serviceInfo);
  }
  const billingInfo = sessionStorage.getItem('billingInfo');
  let billingInformation = null;
  if (billingInfo) {
    billingInformation = JSON.parse(billingInfo);
  }
  const checkout = {
    stepDescription: 'Payment Information',
    stepName: 'Step 3',
    stepStatus: 'Complete'
  };
  sessionStorage.removeItem('purchase');
  pushCheckOutSteps(billingInformation, checkout, null, null, serviceInformation);
};

const getLinkConfig: GetLinkConfig = (
  { _links, saveApiState },
  cart,
  { showModal },
  { apiUrl },
  { hideSpinner, showSpinner },
  { toUrl }
) => (id, data = {}, onComplete, onRetry, onFailure) => {

  if (id === 'maintenance') {
    sessionStorage.removeItem('addServiceInfo');
    sessionStorage.removeItem('addNewServiceInfo');
  }

  if (id === 'add-service-address') {
    const serviceInfo = {
      city: data.city,
      state: data.state,
      zipCode: data.zipcode
    };
    sessionStorage.setItem('addServiceInfo', 'true');
    sessionStorage.setItem('serviceInfo', JSON.stringify(serviceInfo));
  } else if (id === 'retry-current-address') {
    serviceInfoComplete();
  } else if (id === 'retry-new-address') {
    const serviceInfo = {
      city: data.city,
      state: data.state,
      zipCode: data.zipcode
    };
    sessionStorage.setItem('addNewServiceInfo', 'true');
    sessionStorage.setItem('serviceInfo', JSON.stringify(serviceInfo));
  } else if (id === 'add-billing-address') {
    const serviceInfo = sessionStorage.getItem('serviceInfo');
    let serviceInformation = null;
    if (serviceInfo) {
      serviceInformation = JSON.parse(serviceInfo);
    }
    const checkout = {
      stepDescription: 'Billing Information',
      stepName: 'Step 2',
      stepStatus: 'Complete'
    };
    const billingInfo = {
      city: serviceInformation?.city,
      frequency: data.billingFrequency,
      sameAsServiceAddress: '1',
      state: serviceInformation?.state,
      zipCode: serviceInformation?.zipCode
    };
    sessionStorage.setItem('billingInfo', JSON.stringify(billingInfo));
    pushCheckOutSteps(billingInfo, checkout, null, null, serviceInformation);
  } else if (id === 'purchase') {
    sessionStorage.setItem('purchase', 'true');
  }

  const { body: linkBody, href, method } = _links?.[id] ?? {};

  const requestData = transformRequestData({ cart, data, id, linkBody });

  const query = method === 'GET' ? requestData : {};
  const body = method === 'GET' ? null : { ...linkBody, ...requestData };

  const url = toUrl({ base: apiUrl, href, query });

  return { body, hideSpinner, id, method, onComplete, onFailure, onRetry, saveApiState, showModal, showSpinner, url };
};

export default () => {
  const link = getLinkConfig(
    useApiState(), useCart(), useModal(), useSiteMetadata(), useSpinner(), useUrl()
  );

  return {
    addBillingAddress: (data: any, onComplete: EmptyFunction, onRetry: any) => request(link(ADD_BILLING_ADDRESS, data, onComplete, onRetry)),
    addServiceAddress: (data: any, onComplete: EmptyFunction, onRetry: any) => request(link(ADD_SERVICE_ADDRESS, data, onComplete, onRetry)),
    applyDiscount: (data: any, onComplete?: any) => request(link(APPLY_DISCOUNT, data, onComplete)),
    getPlans: (data: any, onComplete: EmptyFunction, onFailure?: any) => request(link(GET_PLANS, data, onComplete, undefined, onFailure)),
    maintenance: (onComplete?: any, onFailure?: any) => request(link(MAINTENANCE_PAGE, onComplete, onFailure)),
    purchase: (data: any, onComplete: EmptyFunction, onFailure: any) => request(link(PURCHASE, data, onComplete, undefined, onFailure)),
    retryCurrentAddress: (data: any, onComplete: EmptyFunction) => request(link(RETRY_CURRENT_ADDRESS, data, onComplete)),
    retryNewAddress: (data: any, onComplete: EmptyFunction, onRetry: any) => request(link(RETRY_NEW_ADDRESS, data, onComplete, onRetry)),
    retrySuggestedAddress: (data: any, onComplete: EmptyFunction) => request(link(RETRY_SUGGESTED_ADDRESS, data, onComplete))
  };
};
